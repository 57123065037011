<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-md-12">
							<h5 class="mb-4">
								<strong>Detalle de Usuario</strong>
							</h5>
							<hr class="hrText" data-content="♦" />
						</div>
					</div>
					<usersDetail />
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
import usersDetail from '@/components/users-detail'

export default {
	name: 'usersDetailView',
	components: {
		usersDetail,
	},
	computed: {
		spinnerLoaderLabel() {
			return this.$store.state.users.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.users.spinnerLoader
		},
	},
}
</script>