<template>
	<div v-if="menu && !actualUser.is_admin">
		<div v-for="(element, index) in menu" :key="index">
			<div v-if="element.length > 0 && element[0].module_id">
				<div class="row bg-gray-4 p10_0">
					<div class="col-md-10 text-center">{{ element[0].menu_title }}</div>
					<div class="col-md-2 text-center">Acceso</div>
				</div>
				<div :class="[i % 2 == 0 ? 'bg-gray-1' : 'bg-gray-2']" class="row p10_0" v-for="(e, i) in element" :key="i">
					<div class="col-md-10 pl25">{{ e.module_title }}</div>
					<div class="col-md-2 text-center">
						<input
							type="checkbox"
							:checked="permissions[e.menu_key][e.module_key]"
							@click="onChangePermission(e.menu_key, e.module_key)"
							v-if="utilities(permissions, [e.menu_key, e.module_key], false)"
						/>
						<input type="checkbox" @click="onChangePermission(e.menu_key, e.module_key)" v-else />
					</div>
				</div>
			</div>
		</div>
		<div class="row pt20">
			<div class="col-md-4 text-left">
				<a-button class="btn btn-warning" icon="arrow-left" @click="onCancel">Regresar</a-button>
			</div>
			<div class="col-md-8 text-right">
				<a-button icon="save" class="btn btn-success" @click="updatePermissions">Actualizar permisos</a-button>
			</div>
		</div>
	</div>
	<div v-else>
		<h2 class="text-center text-muted">El usuario es administrador y no requiere administración de permisos</h2>
		<p class="text-center text-muted">En caso de requerir administrar permisos, se debe cambiar el tipo de usuario en el panel "Información General"</p>
	</div>
</template>

<script>
//
import { mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import _ from 'lodash'
import Swal from 'sweetalert2'
import utilities from '@/services/utilities'

export default {
	name: 'usersPermissionsComponent',
	directives: {
		mask,
	},
	data() {
		return {
			permissions: {},
		}
	},
	computed: {
		...mapGetters('users', ['actualUser', 'menu']),
	},
	beforeMount() {
		if (Object.keys(this.menu).length == 0) {
			this.$store.dispatch('users/GET_MENU')
		}
	},
	mounted() {
		if (utilities.objectValidate(this.actualUser, 'permissions', false)) {
			this.setPermissionInitial()
			this.permissions = _.cloneDeep(this.actualUser.permissions)
		}
	},
	methods: {
		utilities,
		setPermissionInitial() {
			Object.keys(this.menu).forEach((key) => {
				let modules = this.menu[key]
				modules.forEach((element) => {
					utilities.objectUpdate(this.permissions, [key, element.module_key], false)
				})
			})
		},
		onChangePermission(category, module) {
			utilities.objectUpdate(this.permissions, [category, module], !this.permissions[category][module])
		},
		onCancel() {
			this.$router.replace('/system/users')
		},
		updatePermissions() {
			let payload = {
				permissions: this.permissions,
				user_id: this.$route.params.id,
			}
			this.$store.dispatch('users/UPDATE_PERMISSIONS', payload)
		},
		getMenuValue(category, module, permission) {
			if (utilities.objectValidate(this.permissions, [category, module, permission], false)) {
				return true
			} else {
				utilities.objectUpdate(this.permissions, [category, module, permission], false)
				return false
			}
		},
	},
	watch: {
		menu: {
			deep: true,
			handler() {
				this.setPermissionInitial()
			},
		},
	},
}
</script>