<template>
	<div>
		<a-tabs default-active-key="1">
			<a-tab-pane key="1" tab="Información General" force-render>
				<a-form layout="vertical" :form="form" @submit="handleSubmit">
					<div class="row">
						<div class="col-md-4">
							<a-form-item label="Nombre(s)">
								<a-input
									v-decorator="[
										'name',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												},
											],
										},
									]"
									autocomplete="off"
								/>
							</a-form-item>
						</div>
						<div class="col-md-4">
							<a-form-item label="Apellido Paterno">
								<a-input
									v-decorator="[
										'first_lastname',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												},
											],
										},
									]"
								/>
							</a-form-item>
						</div>
						<div class="col-md-4">
							<a-form-item label="Apellido Materno">
								<a-input v-decorator="['second_lastname']" autocomplete="off" />
							</a-form-item>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<a-form-item label="Nombre clave">
								<a-input
									v-decorator="[
										'username',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												},
											],
										},
									]"
									autocomplete="off"
								/>
							</a-form-item>
						</div>
						<div class="col-md-3">
							<a-form-item label="Correo">
								<a-input
									v-decorator="[
										'email',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												},
											],
										},
									]"
									autocomplete="off"
								/>
							</a-form-item>
						</div>
						<div class="col-md-3">
							<a-form-item label="Teléfono celular">
								<a-input
									v-decorator="[
										'mobile_number',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												},
											],
										},
									]"
									autocomplete="off"
									v-mask="'##########'"
								/>
							</a-form-item>
						</div>
						<div class="col-md-3">
							<a-form-item label="Usuario administrador">
								<a-select placeholder="Selecciona" :initial-value="0" v-decorator="['is_admin']">
									<a-select-option :value="1">Si</a-select-option>
									<a-select-option :value="0">No</a-select-option>
								</a-select>
							</a-form-item>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3" v-if="!isUserAdmin && !form.getFieldValue('is_admin')">
							<a-form-item label="Áreas">
								<a-select placeholder="Selecciona" mode="multiple" :initial-value="[]" v-decorator="['areas']">
									<a-select-option v-for="(area, index) in businessAreasList" :key="index" :value="area.id">{{ area.name }}</a-select-option>
								</a-select>
							</a-form-item>
						</div>
						<div class="col-md-3">
							<a-form-item label="Contraseña">
								<a-input-password
									v-decorator="[
										'password',
										{
											rules: [
												{
													min: 6,
													message: 'Ingrese contraseña de al menos 6 caracteres.',
												},
												{
													validator: validateToNextPassword,
												},
											],
										},
									]"
								/>
							</a-form-item>
						</div>
						<div class="col-md-3">
							<a-form-item label="Confirmar contraseña">
								<a-input-password
									@blur="handleConfirmBlur"
									v-decorator="[
										'confirmPassword',
										{
											rules: [
												{
													min: 6,
													message: 'Ingrese contraseña de al menos 6 caracteres.',
												},
												{
													validator: compareToFirstPassword,
												},
											],
										},
									]"
									autocomplete="off"
								/>
							</a-form-item>
						</div>
						<div class="col-md-3">
							<div class="btnPasswordGenerator">
								<a-button icon="lock" class="mr-1 btn btn-info btn-block" @click="onGeneratePassword">Generar contraseña</a-button>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-4 text-left">
							<a-button class="btn btn-warning" icon="arrow-left" @click="onCancel">Regresar</a-button>
						</div>
						<div class="col-md-8 text-right">
							<a-button icon="save" class="btn btn-success" htmlType="submit">Guardar</a-button>
						</div>
					</div>
				</a-form>
			</a-tab-pane>
			<a-tab-pane key="2" tab="Permisos" force-render>
				<usersPermissions />
			</a-tab-pane>
		</a-tabs>
	</div>
</template>
<script>
//
import { mapGetters } from 'vuex'
import utilities from '@/services/utilities'
import _ from 'lodash'
import { mask } from 'vue-the-mask'
import usersPermissions from '@/components/users-permissions'

export default {
	name: 'usersDetail',
	components: {
		usersPermissions,
	},
	directives: {
		mask,
	},
	data() {
		return {
			form: this.$form.createForm(this),
		}
	},
	beforeMount() {
		if (this.businessAreasList.length == 0) {
			this.$store.dispatch('businessAreas/GET')
		}
	},
	mounted() {
		let selectedAreas = []
		if (utilities.objectValidate(this.actualUser, 'areas', false)) {
			selectedAreas = this.actualUser.areas.map((element) => {
				return element.id
			})
		}
		this.form.setFieldsValue({
			name: utilities.objectValidate(this.actualUser, 'name', ''),
			first_lastname: utilities.objectValidate(this.actualUser, 'first_lastname', ''),
			second_lastname: utilities.objectValidate(this.actualUser, 'second_lastname', ''),
			username: utilities.objectValidate(this.actualUser, 'username', ''),
			email: utilities.objectValidate(this.actualUser, 'email', ''),
			mobile_number: utilities.objectValidate(this.actualUser, 'mobile_number', ''),
			is_admin: utilities.objectValidate(this.actualUser, 'is_admin', ''),
			areas: selectedAreas,
		})
	},
	computed: {
		...mapGetters('user', ['user']),
		...mapGetters('users', ['usersList']),
		...mapGetters('businessAreas', ['businessAreasList']),
		...mapGetters('users', ['actualUser']),
		isUserAdmin() {
			return !!this.form.getFieldValue('is_admin')
		},
	},

	methods: {
		onCancel() {
			this.$router.replace('/system/users')
		},
		onGeneratePassword() {
			utilities.passwordGenerator().then((passwd) => {
				this.form.setFieldsValue({ password: passwd, confirmPassword: passwd })
			})
		},
		handleConfirmBlur(e) {
			const value = e.target.value
			this.confirmDirty = this.confirmDirty || !!value
		},
		compareToFirstPassword(rule, value, callback) {
			const form = this.form
			if (value && value !== form.getFieldValue('password')) {
				callback('Las contraseñas deben coincidir.')
			} else {
				callback()
			}
		},
		validateToNextPassword(rule, value, callback) {
			const form = this.form
			if (value && this.confirmDirty) {
				form.validateFields(['confirmPassword'], { force: true })
			}
			callback()
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields((err, values) => {
				if (!err) {
					let payload = {
						...values,
						areas: utilities.objectValidate(values, 'areas', []).join(','),
						user_id: Number(this.$route.params.id),
					}
					delete payload.confirmPassword

					this.$store.dispatch('users/UPDATE', payload)
				}
			})
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>